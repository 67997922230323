<template>
  <div class="myCoupon">
    <div class="tab-list"> 
      <van-tabs v-model:active="active" color="#90D12E" title-active-color="#90D12E" title-inactive-color="#666666"
        @click-tab="handleSeachGoods">
        <van-tab title-style="font-size:14px;" :title="$t('未使用')" name="1"></van-tab>
        <van-tab title-style="font-size:14px;" :title="$t('已使用')" name="2"></van-tab>
        <van-tab title-style="font-size:14px;" :title="$t('已過期')" name="3"></van-tab>
      </van-tabs>
    </div>
    <div class="myCoupon-content">
      <div class="myCoupon-list">
        <div v-for="item in CouponList" :key="item.couponId" :class="[
          'myCoupon-item',
          active == '1' ? 'couponbg' : 'couponexpiredbg'
        ]">
          <div class="myCoupon-item-left">
            <div class="myCoupon-item-price">
              <span v-if="item.type !== '6'" :class="['pricesymbol',{ expiredcolor: active != '1' }]">
                HK$</span>

              <span v-if="$route.query.langEnv == '3'">
                <span :class="{ expiredcolor: active != '1' }">{{ item.type === "6"? `${("" + item.discount)[0]}`: item.price}}
                  <span :class="{ expiredcolor: active != '1' }" style="font-size: 14px" v-if="item.type === '6'">{{item.discount % 10 === 0? "": "." + ("" + item.discount)[1]}}折</span>
                </span>
              </span>
              <span v-else>
                <span :class="{ expiredcolor: active != '1' }">{{item.type === "6"? `${item.discount }%OFF`: item.price}}
                  <!-- <span>
                    <span v-if="item.type === '6'" class="discount" :class="{ expiredcolor: active != '1' }">Discount</span>
                  </span> -->
                </span>
              </span>
            </div>
            <div class="myCoupon-item-fullprice">
              <div v-if="$route.query.langEnv == '3'" :class="{ expiredcolor: active != '1' }">
                {{ $t("滿") }} {{ item.metPrice }} {{ $t("可用") }}
              </div>
              <div v-else :class="{ expiredcolor: active != '1' }">
                {{ $t("可用") }} {{ $t("滿") }} {{ item.metPrice }}
              </div>
            </div>
          </div>
          <div class="myCoupon-item-right">
            <div class="name">
              {{ item.name }}
            </div>
            <div class="date">
              <div>{{ item.startDate }}-{{ item.expiredDate }}</div>
              <div class="get-btn" v-if="active == '1'" @click="handleToUse(item)">
                {{ $t("去使用") }}
              </div>
            </div>
            <div class="question" v-if="item.isAllUse || item.type == '40'">
              <div v-if="item.isAllUse">{{ $t("適用於所有的產品") }}</div>
            </div>
            <div v-if="item.type == '40'" class="question">
              <div v-if="$route.query.langEnv == '3'">
                {{ `${$t("最高")}${$t("可减")}HK$${item.maxDiscountPrice}` }}
              </div>
              <div v-else>
                {{ `${$t("最高")}HK$${item.maxDiscountPrice}${$t("可减")}` }}
              </div>
            </div>
          </div>
          <div class="myCoupon-item-img">
            <img v-if="active == '2' && $route.query.langEnv == 3" src="@/assets/image/coupon/used.png" alt="" />
            <img v-if="active == '2' && $route.query.langEnv == 2" src="@/assets/image/coupon/Useden.png" alt="" />
            <img v-if="active == '3' && $route.query.langEnv == 3" src="@/assets/image/coupon/expired.png" alt="" />
            <img v-if="active == '3' && $route.query.langEnv == 2" src="@/assets/image/coupon/invaliden.png" alt="" />
          </div>
        </div>
      </div>
      <van-loading v-if="!isEmpty" size="13px" class="loading">{{ $t("加载中") }}...</van-loading>
      <div v-else class="isEmpty">{{ $t("沒有更多啦") }}~</div>
    </div>
  </div>
</template>

<script>
import { getSearchUserCoupon } from "@/api/coupon.js"
import { handleScroll } from "@/utils/util.js"
import mixins from "@/utils/mixins.js"
import i18n from "@/i18n"
export default {
  mixins: [mixins],
  data () {
    return {
      active: "",
      formPage: {
        pageIndex: 1,
        pageSize: 10,
        isUsed: false,
        isValid: true,
        isInit: true
      },
      CouponList: [],
      isEmpty: false
    }
  },
  async created () {
    const langEnv = this.$route.query.langEnv
    this.handleChangeLang(langEnv, i18n)
    await this.getList()
    document.title = this.$t("優惠券")
  },
  mounted () {
    window.addEventListener("scroll", this.throttle(this.getScrollList, 1000))
  },

  methods: {
    handleToUse () {
      this.$router.replace('/')
      // let _this = this;
      // let func1 = function () {
      // 	mmm.goHome();
      // };
      // let func2 = function () {
      // 	window.webkit.messageHandlers.goHome.postMessage('');
      // };
      // let func3 = function () {

      // };
      // this.excFunc(func1, func2, func3);
    },
    // handleToUse(item) {
    //   let _this = this;
    // 	let func1 = function () {
    // 		mmm.goToSearchCouponProduct(item.id);
    // 	};
    // 	let func2 = function () {
    // 		window.webkit.messageHandlers.goToSearchCouponProduct.postMessage(item.id);
    // 	};
    // 	let func3 = function () {

    // 	};
    // 	this.excFunc(func1, func2, func3);
    // },
    async getScrollList () {
      if (handleScroll()) {
        this.formPage.pageIndex += 1
        await this.getList()
      }
    },
    async getList () {
      if (this.isEmpty) {
        return
      }
      const res = await getSearchUserCoupon(this.formPage, this.$route.query.langEnv)
      if (this.formPage.pageIndex > 1) {
        this.CouponList = [...this.CouponList, ...res.data?.list]
      } else {
        this.CouponList = res.data?.list
        this.isEmpty = true
      }

      if (res.data?.list?.length === 0) {
        this.isEmpty = true
      }
    },
    handleSeachGoods (item) {
      console.log(item)
      this.isEmpty = false
      this.formPage.isUsed = false
      this.formPage.isValid = true
      this.formPage.isInit = true
      if (item.name == "1") this.formPage.isUsed = false
      if (item.name == "2") this.formPage.isUsed = true
      if (item.name == "3") this.formPage.isValid = false
      this.formPage.pageIndex = 1
      this.getList()
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped lang="scss">
.tab-list {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 10;
}

.myCoupon-content {
  background-color: #fff;
  padding: 16px;
  min-height: 100vh;
  padding-top: 60px;

  .myCoupon-item {
    display: flex;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    // padding: 12px;
    margin-bottom: 12px;
    position: relative;

    .myCoupon-item-img {
      position: absolute;
      top: 0;
      right: 0;
      width: 56px;
    }

    .myCoupon-item-left {
      width: 35%;
      padding: 0 5px;
      box-sizing: border-box;

      .myCoupon-item-fullprice {
        color: #e84935;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
      }

      .myCoupon-item-price {
        color: #e84935;

        font-weight: 800;
        text-align: center;
        .discount {
          font-size: 12px;
        }

        .pricesymbol {
          font-size: 12px;
        }

        span {
          color: #e84935;
          font-size: 17px;
        }
      }
    }

    .myCoupon-item-right {
      width: 69%;
      padding: 12px;
      box-sizing: border-box;

      .name {
        min-height: 40px;
        margin-bottom: 4px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
      }

      .date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        font-size: 11px;

        .get-btn {
          font-size: 12px;
          color: #fff;
          padding: 2px 8px;
          background: linear-gradient(270deg,
              #e84935 0%,
              rgba(232, 73, 53, 0.6) 100%);
          border-radius: 16px 16px 16px 16px;
        }
      }

      .question {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999999;

        img {
          width: 12px;
          margin-right: 2px;
        }
      }
    }
  }

  .isEmpty {
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #999999;
    padding-bottom: 10px;
  }

  .loading {
    text-align: center;
    padding-bottom: 10px;
  }

  .couponbg {
    background: url("~@/assets/image/coupon/mycoupon.png") no-repeat;
    background-size: 110% 100%;
  }

  .couponexpiredbg {
    background: url("~@/assets/image/coupon/efficacy.png") no-repeat;
    background-size: 110% 100%;
  }

  .expiredcolor {
    color: #000000 !important;
  }
}
</style>
